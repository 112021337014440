import React, { Component } from 'react';
import './w3.css'
import Jumbotron from 'react-bootstrap/Jumbotron'

class Book extends Component {
    render() {
        return (
            <div className="w3-content w3-padding" style={{ maxWidth: 1564 }}>
                <h3 className="w3-border-bottom w3-border-light-grey w3-padding-48">Book An Appointment</h3>
                <Jumbotron>
                    <h3>Please click on the link below to book an online 
                        appointment with your family doctor.</h3>
                    <p><a href="https://ocean.cognisantmd.com/online-booking/256c17ea-49a7-4b47-84b9-2c7b85bde428" className="w3-button w3-teal">Book Here</a></p>
                    <br/>
                    <p>Online booking is only available for registered patients at this clinic. New patients 
                        cannot book appointments online.</p>
                    <br/>
                    <p>ALL basic appointments are in person at the clinic.</p>
                    <br/>
                    <p style={{fontWeight: "bold"}}>Forms, completes, procedures and chronic disease management appointments cannot be booked online.</p>
                    <p>For these appointment types, please call the clinic at 306-545-3000 
                        and our receptionists will be happy to assist you.</p>
                </Jumbotron>
            </div>
        )
    }
}

export default Book;