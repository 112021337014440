import React, { Component } from 'react';
import './w3.css'

class Footers extends Component {
    render() {
        return (
            <footer className="w3-grey">
                <div className="w3-container w3-padding-32 w3-center">
                    <div className="w3-row-padding w3-padding-16 w3-center">
                        <div className="w3-third w3-margin-bottom">
                            <h6 style={{ fontWeight: "bold" }}>About Stapleford</h6>
                            <p style={{ textAlign: "center" }}>At Stapleford Medical Clinic we respect the well-being and dignity of each of our patients.  We care for the sick, investigate their concerns and work closely with each patient to achieve their health and wellness goals.</p>
                        </div>
                        <div className="w3-third w3-margin-bottom">
                            <h6 style={{ fontWeight: "bold" }}>Contact Us</h6>
                            <p>
                                <i className="fa fa-phone mr-3" /> 306-545-3000
                            </p>
                            <p>
                                <i className="fa fa-fax mr-3" /> 306-545-5584
                            </p>
                        </div>
                        <div className="w3-third w3-margin-bottom w3-xlarge">
                            <h6 style={{ fontWeight: "bold" }}>Social Media</h6>
                            <a href="https://www.facebook.com/staplefordmedicalclinic/" target="_blank"><i class="fa fa-facebook-official w3-hover-opacity"></i></a>
                            <a href="https://www.instagram.com/staplefordmedicalclinic/" target="_blank"><i class="fa fa-instagram w3-hover-opacity"></i></a>
                        </div>
                    </div>
                </div>

                <div className="w3-container w3-padding-32 w3-center w3-dark-grey">© 2019 Copyright: Stapleford Medical Clinic</div>
            </footer>
        )
    }
}

export default Footers;