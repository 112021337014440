import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './w3.css'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import staplefordLogo from './staplefordLogo.svg'

class TopBar extends Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { width } = this.state;
        const isMobile = width <= 500;

        if(isMobile) {
            return(
                <Navbar className="w3-teal" expand="false" variant="dark">
                    <Navbar.Brand href="/">
                        <img
                        alt="Logo"
                        src={staplefordLogo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"  
                        />
                        {' Stapleford Medical Clinic'}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            
                        </Nav>
                        <Nav>
                            <NavLink to="/" className="nav-link">Home</NavLink>
                            <NavLink to="/book" className="nav-link">Book An Appointment</NavLink>
                            <NavLink to="/announcements" className="nav-link">Announcements</NavLink>
                            <NavLink to="/walkin" className="nav-link">Walk-In</NavLink>
                            <NavLink to="/xray" className="nav-link">X-ray</NavLink>
                            <NavLink to="/team" className="nav-link">Physicians</NavLink>
                            {/* <NavLink to="/massage" className="nav-link">Massage Therapy</NavLink> */}
                            <NavLink to="/nurse" className="nav-link">Nursing Station</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            );
        } else{
            return (
                <Navbar className="w3-teal" variant="dark" fixed="top">
                    <Navbar.Brand href="/">
                        <img
                        alt="Logo"
                        src={staplefordLogo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"  
                        />
                        {' Stapleford Medical Clinic'}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            
                        </Nav>
                        <Nav>
                            <NavLink to="/" className="nav-link">Home</NavLink>
                            <NavLink to="/book" className="nav-link">Book An Appointment</NavLink>
                            <NavLink to="/announcements" className="nav-link">Announcements</NavLink>
                            <NavLink to="/walkin" className="nav-link">Walk-In</NavLink>
                            <NavLink to="/xray" className="nav-link">X-ray</NavLink>
                            <NavLink to="/team" className="nav-link">Physicians</NavLink>
                            {/* <NavDropdown title="Hours" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/">Walk-in Clinic Hours</NavDropdown.Item>
                                <NavDropdown.Item href="/">X-ray Hours</NavDropdown.Item>
                                <NavDropdown.Item href="/">Regular Clinic Hours</NavDropdown.Item>
                            </NavDropdown> */}
                            {/* <NavLink to="/massage" className="nav-link">Massage Therapy</NavLink> */}
                            <NavLink to="/nurse" className="nav-link">Nursing Station</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            );
        }
    }
}

export default TopBar;