import React, { Component } from 'react';
import './w3.css'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Media from 'react-bootstrap/Media'
import massage_therapists from './massage_therapists.jpg'

class Massage extends Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { width } = this.state;
        const isMobile = width <= 500;

        if(isMobile){
            return (
                <div className="w3-content w3-padding" style={{ maxWidth: 1564 }}>
                    <h3 className="w3-border-bottom w3-border-light-grey w3-padding-48">Massage Therapy</h3>
                    <div className="w3-row-padding w3-padding-16 w3-center"></div>
    
                    <Jumbotron>
                    <p style={{ textAlign: "left" }}>Welcome to Stapleford Massage Therapy. 
                        With 3 highly skilled therapists having a combined 40 years of experience we offer Relaxation and Therapeutic massage. 
                        Our treatment focus is acute and chronic conditions, pre and post natal, headaches, TMJ dysfunctions, motor vehicle and work related injuries. 
                        To book an appointment with one of our therapists you can call our clinic at <b>306.545.3000</b> or go online <a href="https://staplefordmassage.coconutcalendar.com" class="w3-text-blue">https://staplefordmassage.coconutcalendar.com</a></p>
                    </Jumbotron>

                    <Jumbotron>
                    <p style={{ textAlign: "left" }}>Stapleford Massage Therapy 
                        will be temporarily closed to protect clients and staff from any risk of contacting the COVID-19 Virus. 
                        We will post our re-opening date as soon as this threat has passed. 
                        Thank you for your support that you have shown us over the years and we hope to see you soon.</p>
                    </Jumbotron>
    
                    <div className="w3-container w3-padding-32">
                        <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Hours</h3>
                        <div className="w3-display-container">
                            <table className="w3-table w3-striped w3-bordered">
                                <thead>
                                    <tr className="w3-teal">
                                        <th>Day</th>
                                        <th>Hours</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <th>Monday</th>
                                    <td>9:00 AM - 8:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Tuesday</th>
                                    <td>9:00 AM - 8:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Wednesday</th>
                                    <td>9:00 AM - 8:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Thursday</th>
                                    <td>9:00 AM - 8:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Friday</th>
                                    <td>9:00 AM - 8:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Saturday</th>
                                    <td>10:00 AM - 3:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Sunday</th>
                                    <td>10:00 AM - 3:00 PM</td>
                                </tr>
                            </table>
                        </div>
                    </div>
    
                    <div className="w3-container w3-padding-32">
                        <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Pricing</h3>
                        <div className="w3-display-container">
                            <table className="w3-table w3-striped w3-bordered">
                                <thead>
                                    <tr className="w3-teal">
                                        <th>Time</th>
                                        <th>Price (GST Included)</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <th>30 min</th>
                                    <td>$50.00</td>
                                </tr>
                                <tr>
                                    <th>45 min</th>
                                    <td>$65.00</td>
                                </tr>
                                <tr>
                                    <th>60 min</th>
                                    <td>$75.00</td>
                                </tr>
                                <tr>
                                    <th>75 min</th>
                                    <td>$80.00</td>
                                </tr>
                                <tr>
                                    <th>90 min</th>
                                    <td>$110.00</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            );
        } else{
            return (
                <div className="w3-content w3-padding" style={{ maxWidth: 1564 }}>
                    <h3 className="w3-border-bottom w3-border-light-grey w3-padding-48">Massage Therapy</h3>
                    <div className="w3-row-padding w3-padding-16 w3-center"></div>
    
                    <Media>
                        <img
                            width={375.1902587519}
                            height={500}
                            className="mr-3"
                            src={massage_therapists}
                            alt="Massage Therapists"
                        />
                        <Media.Body>
                            <Jumbotron>
                            <p style={{ textAlign: "left" }}>Welcome to Stapleford Massage Therapy. 
                                With 3 highly skilled therapists having a combined 40 years of experience we offer Relaxation and Therapeutic massage. 
                                Our treatment focus is acute and chronic conditions, pre and post natal, headaches, TMJ dysfunctions, motor vehicle and work related injuries. 
                                To book an appointment with one of our therapists you can call our clinic at <b>306.545.3000</b> or go online <a href="https://staplefordmassage.coconutcalendar.com" class="w3-text-blue">https://staplefordmassage.coconutcalendar.com</a></p>
                            </Jumbotron>
                        </Media.Body>
                    </Media>

                    <Jumbotron>
                    <p>Stapleford Massage Therapy 
                        will be temporarily closed to protect clients and staff from any risk of contacting the COVID-19 Virus. 
                        We will post our re-opening date as soon as this threat has passed. 
                        Thank you for your support that you have shown us over the years and we hope to see you soon.</p>
                    </Jumbotron>
    
                    <div className="w3-container w3-padding-32">
                        <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Hours</h3>
                        <div className="w3-display-container">
                            <table className="w3-table w3-striped w3-bordered">
                                <thead>
                                    <tr className="w3-teal">
                                        <th>Day</th>
                                        <th>Hours</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <th>Monday</th>
                                    <td>9:00 AM - 8:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Tuesday</th>
                                    <td>9:00 AM - 8:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Wednesday</th>
                                    <td>9:00 AM - 8:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Thursday</th>
                                    <td>9:00 AM - 8:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Friday</th>
                                    <td>9:00 AM - 8:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Saturday</th>
                                    <td>10:00 AM - 3:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Sunday</th>
                                    <td>10:00 AM - 3:00 PM</td>
                                </tr>
                            </table>
                        </div>
                    </div>
    
                    <div className="w3-container w3-padding-32">
                        <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Pricing</h3>
                        <div className="w3-display-container">
                            <table className="w3-table w3-striped w3-bordered">
                                <thead>
                                    <tr className="w3-teal">
                                        <th>Time</th>
                                        <th>Price (GST Included)</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <th>30 min</th>
                                    <td>$50.00</td>
                                </tr>
                                <tr>
                                    <th>45 min</th>
                                    <td>$65.00</td>
                                </tr>
                                <tr>
                                    <th>60 min</th>
                                    <td>$75.00</td>
                                </tr>
                                <tr>
                                    <th>75 min</th>
                                    <td>$80.00</td>
                                </tr>
                                <tr>
                                    <th>90 min</th>
                                    <td>$110.00</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Massage;