import React, { Component } from 'react';
import './w3.css'
import Card from 'react-bootstrap/Card'
import dr_mohamed from './dr_mohamed2.jpg'
import dr_lotz from './dr_lotz2.jpg'
import dr_ogundeji from './dr_ogundeji2.jpg'
import dr_oluma from './dr_oluma2.jpg'
import dr_warden from './dr_warden2.jpg'
import dr_amos from './dr_amos2.jpg'
import dr_elgendy from './dr_elgendy2.jpg'
import dr_cheema from './dr_cheema.jpg'
import dr_pushpika from './dr_pushpika.jpg'
import dr_andraws from './dr_andraws.jpg'
import dr_dulydamode from './dr_dulydamode.jpg'
import dr_bodemer from './dr_bodemer.jpg'
import dr_hasantha from './dr_hasantha.jpg'
import dr_ziegler from './dr_ziegler.jpg'

class Team extends Component {
    render() {
        return (
            <div className="w3-content w3-padding" style={{ maxWidth: 900 }}>
                <h3 className="w3-border-bottom w3-border-light-grey w3-padding-48">Stapleford Clinic Team</h3>
                <div className="w3-row-padding w3-padding-16 w3-center">
                    <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                        <Card.Img variant="top" style={{ width: "100%" }} src={dr_lotz} />
                        <Card.Body>
                            <Card.Title>Dr. Barry Lotz</Card.Title>
                            <Card.Text>
                                Family Physician
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                        <Card.Img variant="top" style={{ width: "100%" }} src={dr_oluma} />
                        <Card.Body>
                            <Card.Title>Dr. Elias Oluma</Card.Title>
                            <Card.Text>
                                Family Physician
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                        <Card.Img variant="top" style={{ width: "100%" }} src={dr_mohamed} />
                        <Card.Body>
                            <Card.Title>Dr. Ash Mohamed</Card.Title>
                            <Card.Text>
                                Family Physician
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="w3-row-padding w3-padding-16 w3-center">
                    <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                        <Card.Img variant="top" style={{ width: "100%" }} src={dr_amos} />
                        <Card.Body>
                            <Card.Title>Dr. Taiwo Amos</Card.Title>
                            <Card.Text>
                                Family Physician
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                        <Card.Img variant="top" style={{ width: "100%" }} src={dr_pushpika} />
                        <Card.Body>
                            <Card.Title>Dr. P. Karunatilake</Card.Title>
                            <Card.Text>
                                Family Physician
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                        <Card.Img variant="top" style={{ width: "100%" }} src={dr_elgendy} />
                        <Card.Body>
                            <Card.Title>Dr. M. Elgendy</Card.Title>
                            <Card.Text>
                                Family Physician
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="w3-row-padding w3-padding-16 w3-center">
                    <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                        <Card.Img variant="top" style={{ width: "100%" }} src={dr_dulydamode} />
                        <Card.Body>
                            <Card.Title>Dr. M. Dulymamode</Card.Title>
                            <Card.Text>
                                Family Physician
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                        <Card.Img variant="top" style={{ width: "100%" }} src={dr_bodemer} />
                        <Card.Body>
                            <Card.Title>Dr. K. Bodemer</Card.Title>
                            <Card.Text>
                                Family Physician
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                        <Card.Img variant="top" style={{ width: "100%" }} src={dr_hasantha} />
                        <Card.Body>
                            <Card.Title>Dr. Hasantha</Card.Title>
                            <Card.Text>
                                Family Physician
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="w3-row-padding w3-padding-16 w3-center">
                    <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                        <Card.Img variant="top" style={{ width: "100%" }} src={dr_ziegler} />
                        <Card.Body>
                            <Card.Title>Dr. C. Ziegler</Card.Title>
                            <Card.Text>
                                Family Physician
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        )
    }
}

export default Team;