import React, { Component } from 'react';
import './w3.css'
import Jumbotron from 'react-bootstrap/Jumbotron'

class Announcements extends Component {
    render() {
        return (
            <div className="w3-content w3-padding" style={{ maxWidth: 1564 }}>
                <h3 className="w3-border-bottom w3-border-light-grey w3-padding-48">Announcements Page</h3>
                <Jumbotron>
                    <p style={{fontWeight: "bold"}}>Due to personal circumstances, Dr. Anmol Cheema’s last day at Stapleford Medical Clinic is September 28, 2023. 
                        We wish her all the best and hope to have her return to our clinic in the future.</p>
                </Jumbotron>
            </div>
        )
    }
}

export default Announcements;