import React, { Component } from 'react';
import './w3.css'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Media from 'react-bootstrap/Media'
import nurses from './nurses.jpg'

class Nurse extends Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { width } = this.state;
        const isMobile = width <= 500;

        if(isMobile) {
            return(
                <div className="w3-content w3-padding" style={{ maxWidth: 1564 }}>
                    <h3 className="w3-border-bottom w3-border-light-grey w3-padding-48">Nursing Station</h3>
                    <div className="w3-row-padding w3-padding-16 w3-center"></div>
                    <Jumbotron>
                    <p style={{ textAlign: "left" }}>
                    Our Nursing Department is open weekdays, Monday to Friday, 8:30 am – 4:45 pm 
                    and closed on all statutory holidays.  For patients of Stapleford Medical Clinic, 
                    we have two nurses ready to assist our physicians with the following tests and procedures:
                    </p>
                    <ul style={{ textAlign: "left" }}>
                        <li>24 hour Blood Pressure Testing</li>
                        <li>Audiometry (hearing tests)</li>
                        <li>Ear Syringing</li>
                        <li>Chronic Disease Management</li>
                        <li>ECG</li>
                        <li>Injections (including some immunizations)</li>
                        <li>Spirometry</li>
                        <li>Suture and staple removals</li>
                        <li>Dressings</li>
                        <li>Urinalysis / Blood Glucose Testing / Pregnancy Tests</li>
                    </ul>  
                    </Jumbotron>
                </div>
            );
        } else{
            return (
                <div className="w3-content w3-padding" style={{ maxWidth: 1564 }}>
                    <h3 className="w3-border-bottom w3-border-light-grey w3-padding-48">Nursing Station</h3>
                    <div className="w3-row-padding w3-padding-16 w3-center"></div>
                    <Media>
                        <img
                            width={375.1902587519}
                            height={500}
                            className="mr-3"
                            src={nurses}
                            alt="Nurses"
                        />
                        <Media.Body>
                            <Jumbotron>
                            <p style={{ textAlign: "left" }}>
                            Our Nursing Department is open weekdays, Monday to Friday, 8:30 am – 4:45 pm 
                            and closed on all statutory holidays.  For patients of Stapleford Medical Clinic, 
                            we have two nurses ready to assist our physicians with the following tests and procedures:
                            </p>
                            <ul style={{ textAlign: "left" }}>
                                <li>24 hour Blood Pressure Testing</li>
                                <li>Audiometry (hearing tests)</li>
                                <li>Ear Syringing</li>
                                <li>Chronic Disease Management</li>
                                <li>ECG</li>
                                <li>Injections (including some immunizations)</li>
                                <li>Spirometry</li>
                                <li>Suture and staple removals</li>
                                <li>Dressings</li>
                                <li>Urinalysis / Blood Glucose Testing / Pregnancy Tests</li>
                            </ul>  
                            </Jumbotron>
                        </Media.Body>
                    </Media>
                </div>
            )
        }
    }
}

export default Nurse;