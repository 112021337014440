import React, { Component } from 'react';
import './w3.css'
import Carousel from 'react-bootstrap/Carousel'

class ImgHeader extends Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { width } = this.state;
        const isMobile = width <= 500;

        if(isMobile) {
            return (
                <Carousel>
                    <Carousel.Item>
                        <img 
                            className="d-block w-100"
                            src="https://www.necam.com/images/NEC-Healthcare_FemaleDoctorCheckingTablet.jpg"
                            alt="Second Slide"
                        />
                        <Carousel.Caption>
                            <h6 className="w3-text-teal">STAPLEFORD MEDICAL CLINIC</h6>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            );
        } else {
            return (
                <Carousel>
                    <Carousel.Item>
                        <img 
                            className="d-block w-100"
                            src="https://www.necam.com/images/NEC-Healthcare_FemaleDoctorCheckingTablet.jpg"
                            alt="Second Slide"
                        />
                        <Carousel.Caption>
                            <h3 className="w3-text-teal">WELCOME TO STAPLEFORD MEDICAL CLINIC</h3>
                            <p className="w3-text-teal">Visit Our Clinic and Get Premium Service</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            )
        }
    }
}

export default ImgHeader;