import React, { Component } from 'react';
import './w3.css'

class ClinicMap extends Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { width } = this.state;
        const isMobile = width <= 500;

        if(isMobile) {
            return(
                <div className="w3-container">
                    <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Location</h3>
                    <iframe className="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2539.2728347657835!2d-104.66815188405542!3d50.4732642936015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x531c1c27a39ce313%3A0x8b9ea336a7c0195c!2s372+McCarthy+Blvd%2C+Regina%2C+SK+S4R+7M2!5e0!3m2!1sen!2sca!4v1556167113143!5m2!1sen!2sca" width="100%" height="300" frameborder="0" style={{border:0}} allowfullscreen></iframe>
                </div>
            );
        } else{
            return (
                <div className="w3-container">
                    <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Location</h3>
                    <iframe className="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2539.2728347657835!2d-104.66815188405542!3d50.4732642936015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x531c1c27a39ce313%3A0x8b9ea336a7c0195c!2s372+McCarthy+Blvd%2C+Regina%2C+SK+S4R+7M2!5e0!3m2!1sen!2sca!4v1556167113143!5m2!1sen!2sca" width="100%" height="768" frameborder="0" style={{border:0}} allowfullscreen></iframe>
                </div>
            );
        }
    }
}

export default ClinicMap;