import React, { Component } from 'react';
import './w3.css'
import Jumbotron from 'react-bootstrap/Jumbotron'

class Xray extends Component {
    render() {
        return (
            <div className="w3-content w3-padding" style={{ maxWidth: 1564 }}>
                <h3 className="w3-border-bottom w3-border-light-grey w3-padding-48">Stapleford X-ray</h3>
                <Jumbotron>
                    <p>Welcome to our clean and friendly X-ray department where safety and patient care is a priority.</p>
                    <p>We offer walk-in service. No appointments are required.</p>
                    <br/>
                    <h3 style={{fontWeight: "bold"}}>HOURS OF OPERATION:</h3>
                    <p style={{fontWeight: "bold"}}>Open Monday through Friday 9:00 a.m. – 5:30 p.m.</p>
                    <p style={{fontWeight: "bold"}}>Closed over lunch 12:30 p.m. – 1:30 p.m.</p>
                    <p style={{fontWeight: "bold"}}>We are closed all statutory holidays.</p>
                    <br/>
                    <p>**We register our last patient a half hour before closing each day.**</p>
                    <br/>
                    <p>Please bring your requisition from the doctor, as well as a valid health card.</p>
                    <p>We look forward to being of service to you.</p>
                </Jumbotron>
            </div>
        )
    }
}

export default Xray;