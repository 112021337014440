import React from 'react';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import TopBar from './NavBar.js'
import ImgHeader from './ImgHeader.js'
import Home from './Home.js'
import Team from './Team.js'
import Massage from './Massage.js'
import Image from 'react-bootstrap/Image'
import Announcements from './Announcements';
import Footers from './footer.js'
import Nurse from './Nurse.js'
import Book from './Book';
import Walkin from './Walkin';
import Xray from './Xray';

function App() {
  return (
    <Router>
      <div className="App">
        <TopBar />
        <Route exact path="/" component={HomeFunc} />
        <Route exact path="/book" component={BookFunc} />
        <Route exact path="/announcements" component={AnnouncementsFunc} />
        <Route exact path="/walkin" component={WalkinFunc} />
        <Route exact path="/xray" component={XrayFunc} />
        <Route exact path="/team" component={TeamFunc} />
        {/* <Route exact path="/massage" component={MassageFunc} /> */}
        <Route exact path="/nurse" component={NurseFunc} />
        <Footers />
      </div>
    </Router>
  );
}

function HomeFunc(){
  return (
    <div>
      {/* <ImgHeader /> */}
      <Home />
    </div>
  );
}

function BookFunc(){
  return (
    <div>
      <Book />
    </div>
  );
}

function AnnouncementsFunc(){
  return (
    <div>
      <Announcements />
    </div>
  );
}

function WalkinFunc(){
  return (
    <div>
      <Walkin />
    </div>
  );
}

function XrayFunc(){
  return (
    <div>
      <Xray />
    </div>
  );
}

function TeamFunc(){
  return (
    <div>
      <Team />
    </div>
  );
}

// function MassageFunc(){
//   return(
//     <div>
//       <Massage />
//     </div>
//   );
// }

function NurseFunc(){
  return(
    <div>
      <Nurse />
    </div>
  );
}


export default App;
