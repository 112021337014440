import React, { Component } from 'react';
import './w3.css'
import Jumbotron from 'react-bootstrap/Jumbotron'

class Walkin extends Component {
    render() {
        return (
            <div className="w3-content w3-padding" style={{ maxWidth: 1564 }}>
                <h3 className="w3-border-bottom w3-border-light-grey w3-padding-48">Stapleford Walk-In Clinic</h3>
                <Jumbotron>
                    <h3 style={{fontWeight: "bold"}}>Weekend &amp; Statutory Holiday Hours</h3>
                    <p>Saturday, Sunday &amp; Statutory holidays 10:00 a.m. - 02:00 p.m.</p>
                    <p>Registration begins at 09:30 a.m. (Closed Christmas Day)</p>
                    <br/>
                    <h3 style={{fontWeight: "bold"}}>Weekday Hours</h3>
                    <p>Monday – Friday 10:00 a.m. – 07:00 p.m.</p>
                    <p>Registration begins at 09:45 a.m. (Closed on Statutory Holidays)</p>
                    <br/>
                    <p>***Please note, we stop accepting patients before our closing time. If you need to 
                        be seen by a doctor, we recommend that you come earlier in the day, call before 
                        coming or go to medimap online to ensure we are still accepting patients****</p>
                    <br/>
                    <h4 style={{fontWeight: "bold", textAlign: 'left'}}>Frequently asked questions:</h4>
                    <p style={{textAlign: 'left'}} className="font-italic">1. What if I need pain medication or narcotics?</p>
                    <p style={{textAlign: 'left'}}>Only your family physician is able to prescribe or renew your prescriptions for 
                    pain medication or narcotics.</p>
                    <p style={{textAlign: 'left'}} className="font-italic">2. Will doctors complete forms during walk-in clinic?</p>
                    <p style={{textAlign: 'left'}}>You will need to book an appointment with your family doctor for completion of 
                    forms as these take additional time to complete.</p>
                    <p style={{textAlign: 'left'}} className="font-italic">3. Can I come in for a procedure during walk-in clinic?</p>
                    <p style={{textAlign: 'left'}}>It is best to book an appointment with your family physician in order to allocate the 
                    appropriate amount of time that is required for a specific procedure.</p>
                </Jumbotron>
            </div>
        )
    }
}

export default Walkin;