import React, { Component } from 'react';
import './w3.css'
import Carousel from 'react-bootstrap/Carousel'
import Card from 'react-bootstrap/Card'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ClinicMap from './ClinicMap.js'
import Media from 'react-bootstrap/Media'
import virus from './stop_sign.png'
import admin from './Admin.jpg'
import nursing from './Nursing.jpg'
import reception from './Receptions.jpg'
import xray from './X-ray.jpg'
import welcome from './welcome.jpeg'

class Home extends Component {
    render() {
        return (
            <div className="w3-content w3-padding" style={{ maxWidth: 1564 }}>
                <h3 className="w3-border-bottom w3-border-light-grey w3-padding-48">Welcome to Stapleford Medical Clinic.</h3>
                <Jumbotron>
                    <p>Regular Clinic Hours are:</p>
                    <p>Monday – Friday, 8:30 a.m. - 7:00 p.m.</p>
                    <p>Weekends and Statutory Holidays, 10:00 a.m. – 2:00 p.m.</p>
                    <p>“For unexpected closures or sudden changes in hours of operation, please check our Facebook page, which we update daily in these events”.</p>
                    <br/>
                    <h3 style={{fontWeight: "bold"}} className="font-italic">Working together to improve your health.</h3>
                    <br/>
                    <img src={welcome} class="img-fluid" alt="Responsive image"></img>
                    <br/>
                    {/* <p><a href="https://patient.medeohealth.com/booking/stapleford-medical-clinic" className="w3-button w3-teal">Book Here</a></p>
                    <p>or you may contact us directly at 306-545-3000 and our receptionists would be happy to assist you.</p> */}
                </Jumbotron>

                {/* <Jumbotron>
                    <p>Stapleford Medical Clinic is pleased to add online booking and appointment 
                        reminders to our list of services that we provide our patients.</p>
                    <p>Please click on the link below to book online</p>
                    <p>Online Booking is temporarily down. Please contact us directly at 306-545-3000 and our receptionists would be happy to assist you.</p>
                    <br/>
                    <p style={{ fontWeight: "bold" }}>COVID-19 Procedures in place:</p>
                    <p>Upon arrival at the clinic you will be asked to wear a mask and sanitize.</p>
                    <p>If you are experiencing any COVID-19 symptoms please call before coming into the clinic.</p>
                </Jumbotron> */}

                {/* <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Announcements</h3>
                <Jumbotron>
                    <p style={{ fontWeight: "bold" }}>We are updating our Electronic Medical Records System to ensure that you have 
                    the highest level of protection for your information.</p>
                    <br/>
                    <p style={{ fontWeight: "bold" }}>TEMPORARY HOURS:</p>
                    <p>July 5 Regular Clinic Hours / No Evening Walk-in Clinic July 6 – 10 CLOSED</p>
                    <br/>
                    <p>Please make sure that your prescriptions are up-to-date to get you through these few days.</p>
                    <br/>
                    <p>For a list of Walk-In Clinics in the City go to:</p>
                    <p>https://www.rqhealth.ca/facilities/walk-in-clinics</p>
                    <p>If you need advice, call the HealthLine at 811, in case of an emergency call 911, 
                    or go to the emergency department at your nearest hospital.</p>
                    <br/>
                    <p>We do apologise for the inconvenience this brings to our patients.</p>
                    <p style={{ fontStyle: "italic" }}>Take Care &amp; Stay safe.</p>
                </Jumbotron> */}

                {/* <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Hours</h3>
                <p>For unexpected closures or sudden changes in hours of operation, 
                    please check our Facebook page, which we update daily in these events.</p>
                <Tabs defaultActiveKey="walkin" transition={false} id="hours-tabs">
                    <Tab eventKey="walkin" title="Walk-in Clinic">
                        <div className="w3-display-container">
                            <p style={{fontWeight: "bold"}}>Weekend &amp; Statutory Holiday Walk-In Clinic</p>
                            <p>Saturday, Sunday &amp; Statutory holidays 10:00 am - 2:00 pm</p>
                            <p>Registration begins at 9:30 am</p>
                            <p>(Closed Christmas Day)</p>
                            <br/><br/>
                            <p style={{fontWeight: "bold"}}>Weekday Walk-In Clinic</p>
                            <p>Monday – Friday 1:00 pm – 7 pm</p>
                            <p>Registration begins at 12:30 pm</p>
                            <p>(Closed on Statutory Holidays)</p>
                            <br/><br/>
                            <p>Please note, we stop accepting patients before our closing time. If you need to 
                                be seen by a doctor, we recommend that you come earlier in the day or call 
                                before coming to ensure we are still accepting patients.</p>
                        </div>
                    </Tab>
                    <Tab eventKey="xray" title="X-ray">
                        <div className="w3-display-container">
                            <p style={{fontWeight: "bold"}}>*Closed over lunch 12:30 pm – 1:30 pm and all statutory holidays.</p>
                            <table className="w3-table w3-striped w3-bordered">
                                <thead>
                                    <tr className="w3-teal">
                                        <th>Day</th>
                                        <th>Hours</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <th>Monday</th>
                                    <td>9:00 AM - 5:15 PM</td>
                                </tr>
                                <tr>
                                    <th>Tuesday</th>
                                    <td>9:00 AM - 5:15 PM</td>
                                </tr>
                                <tr>
                                    <th>Wednesday</th>
                                    <td>9:00 AM - 5:15 PM</td>
                                </tr>
                                <tr>
                                    <th>Thursday</th>
                                    <td>9:00 AM - 5:15 PM</td>
                                </tr>
                                <tr>
                                    <th>Friday</th>
                                    <td>9:00 AM - 5:15 PM</td>
                                </tr>
                            </table>
                        </div>
                    </Tab>
                    <Tab eventKey="regular" title="Regular Clinic">
                        <div className="w3-display-container">
                            <p style={{fontWeight: "bold"}}>*Closed for all statutory holidays.</p>
                            <table className="w3-table w3-striped w3-bordered">
                                <thead>
                                    <tr className="w3-teal">
                                        <th>Day</th>
                                        <th>Hours</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <th>Monday</th>
                                    <td>8:30 AM - 5:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Tuesday</th>
                                    <td>8:30 AM - 5:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Wednesday</th>
                                    <td>8:30 AM - 5:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Thursday</th>
                                    <td>8:30 AM - 5:00 PM</td>
                                </tr>
                                <tr>
                                    <th>Friday</th>
                                    <td>8:30 AM - 5:00 PM</td>
                                </tr>
                            </table>
                        </div>
                    </Tab>
                </Tabs> */}

                {/* <h3 className="w3-border-bottom w3-border-light-grey w3-padding-48">Announcement</h3>
                <Jumbotron>
                    <h3>Dr. Adetola</h3>
                    <p>has given notice that he will be leaving Stapleford to join his wife at Zoemed Clinic Regina. 
                        His last day will be June 30, 2021. We are sad to see him leave but wish him all the best.</p>
                    <p>Patients are welcome to follow him or transfer to another family doctor at the clinic.</p>
                </Jumbotron> */}

                {/* <h3 className="w3-border-bottom w3-border-light-grey w3-padding-48">Announcements</h3>
                <Carousel>
                    <Carousel.Item>
                        <Jumbotron>
                            <h3>We have a new Instagram account</h3>
                            <h3>Follow us at: <a href="https://www.instagram.com/staplefordmedicalclinic/?hl=en">@staplefordmedicalclinic</a></h3>
                        </Jumbotron>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Jumbotron>
                            <h3>Stapleford Medical Clinic and X-ray Department remain fully staffed 
                                and we are here to serve our patients and the surrounding community</h3>
                            <h3>We have all the safety precautions in place to protect our patients and staff. 
                                Please call for an appointment if you are needing to speak with a family doctor. Stay safe!</h3>
                            <br/>
                            <div className="w3-row-padding w3-padding-16 w3-center">
                                <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                                    <Card.Img variant="top" style={{ width: "100%" }} src={admin} />
                                </Card>
                                <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                                    <Card.Img variant="top" style={{ width: "100%" }} src={nursing} />
                                </Card>
                                <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                                    <Card.Img variant="top" style={{ width: "100%" }} src={reception} />
                                </Card>
                                <Card className="w3-quarter" style={{ width: '18rem', border: "none" }}>
                                    <Card.Img variant="top" style={{ width: "100%" }} src={xray} />
                                </Card>
                            </div>
                        </Jumbotron>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Jumbotron>
                            <Media>
                                <img
                                    width={225}
                                    height={225}
                                    className="mr-3"
                                    src={virus}
                                    alt="Coronavirus Notice"
                                />
                                <Media.Body>
                                    <h2 style={{ textAlign: "left" }}>If you have a cough, fever, difficulty breathing or an acute 
                                    respiratory illness AND have traveled or been in close contact with anyone that has 
                                    traveled in the last 14 days please do not come into the clinic. 
                                    In order to protect patients during the COVID-19 Virus pandemic, 
                                    your physician will now contact you by telephone. 
                                    Please let the receptionist know and they will book you a telephone appointment.</h2>
                                    <h2 style={{ textAlign: "left" }}>If you do not have any of these symptoms, 
                                    you may choose book an appointment in person or by telephone.</h2>
                                </Media.Body>
                            </Media>
                        </Jumbotron>
                    </Carousel.Item>
                </Carousel> */}

                <ClinicMap />
            </div>
        )
    }
}

export default Home;